@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/**
    MOBILE MENU
 */

.mobile-menu{
    position: fixed;
    z-index: 60;
    margin-left: -2rem;
    margin-right: -2rem;
    margin-top: -1.25rem;
    margin-bottom: 1.5rem;
    width: 100%;
    border-bottom-width: 1px;
    border-color: rgb(255 255 255 / 0.08);
    background-color: rgb(var(--color-primary) / 0.9);
}
.dark .mobile-menu{
    background-color: rgb(var(--color-darkmode-800) / 0.9);
}
.mobile-menu:before {
    content: "";
    visibility: hidden;
    position: fixed;
    left: 0px;
    right: 0px;
    z-index: 10;
    height: 100vh;
    width: 100%;
    background-color: rgb(0 0 0 / 0.9);
    opacity: 0;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.mobile-menu.mobile-menu--active:before {
    content: "";
    visibility: visible;
    opacity: 1;
}
.mobile-menu.mobile-menu--active .scrollable{
    margin-left: 0px;
}
.mobile-menu.mobile-menu--active .scrollable .mobile-menu-toggler{
    visibility: visible;
    opacity: 1;
}
@media (max-width: calc(640px - 1px)) {
    .mobile-menu{
        margin-left: -0.75rem;
        margin-right: -0.75rem;
    }
}
.mobile-menu .mobile-menu-bar {
    height: 70px;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
}
@media (max-width: calc(640px - 1px)) {
    .mobile-menu .mobile-menu-bar{
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
}
.mobile-menu .scrollable{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 20;
    margin-left: -100%;
    height: 100vh;
    width: 270px;
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-primary) / var(--tw-bg-opacity));
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.dark .mobile-menu .scrollable{
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-800) / var(--tw-bg-opacity));
}
.mobile-menu .scrollable .mobile-menu-toggler{
    visibility: hidden;
    position: fixed;
    top: 0px;
    right: 0px;
    margin-top: 1rem;
    margin-right: 1rem;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.mobile-menu .scrollable .simplebar-track.simplebar-vertical .simplebar-scrollbar:before{
    background-color: rgb(0 0 0 / 0.5);
}
.mobile-menu ul.menu__sub-open{
    display: block;
}
.mobile-menu ul li {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
.mobile-menu ul li ul{
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    display: none;
    border-radius: 0.5rem;
    background-color: rgb(0 0 0 / 0.1);
}
.dark .mobile-menu ul li ul{
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-700) / var(--tw-bg-opacity));
}
.mobile-menu ul li ul .menu{
    padding-left: 1rem;
    padding-right: 1rem;
}
.mobile-menu ul li ul ul{
    margin-left: 0px;
    margin-right: 0px;
    display: none;
    border-radius: 0.5rem;
    background-color: rgb(0 0 0 / 0.1);
}
.dark .mobile-menu ul li ul ul{
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-600) / var(--tw-bg-opacity));
}
.mobile-menu .menu__devider{
    position: relative;
    height: 1px;
    width: 100%;
    background-color: rgb(255 255 255 / 0.08);
}
.mobile-menu .menu {
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.mobile-menu .menu .menu__title{
    margin-left: 0.75rem;
    display: flex;
    width: 100%;
    align-items: center;
}
.mobile-menu .menu .menu__title .menu__sub-icon{
    margin-left: auto;
    transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.mobile-menu .menu .menu__title .menu__sub-icon svg{
    height: 1.25rem;
    width: 1.25rem;
}
